<template>
  <div class="myLive">
    <div class="title d-flex align-center"><span></span> 我的直播</div>
    <ul class="list">
      <li class="item d-flex align-center" v-for="item in 5" :key="item">
        <div class="order">
          <img src="../../assets/img/live_head.png" alt="" />
        </div>
        <div class="time d-flex flex-column align-center">
          <div class="time-t">27日 周二</div>
          <div class="time-c">19:30-21:30</div>
          <div class="time-b d-flex align-center justify-center">即将开播</div>
        </div>
        <div class="detail">
          <div class="detail-img"></div>
          <div class="detail-name">
            2020下半年教师资格《小学教育教学知识》真题解析直播（二）
          </div>
          <div class="detail-bottom d-flex align-start">
            <div class="teacher">授课老师：刘晓燕</div>
            <div class="status d-flex align-center">
              直播中
              <img
                class="status-img"
                src="../../assets/img/live_z.png"
                alt=""
              />
            </div>
            <div
              class="detail-btn d-flex align-center justify-center cursor"
              :style="btn"
              @click="goLive"
            >
              听课学习
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btn: {
        backgroundImage:
          "url(" + require("../../assets/img/live_btn.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      total: 0, // 记录总条数
      display: 12, // 每页显示条数
      current: 1 // 当前的页数
    };
  },
  mounted() {
    this.getlist();
  },
  methods: {
    goLive() {
      location.href = "./html/live.html?a=" + "asdasd";
    },
    getlist() {
      let _this = this;
      _this.$api
        .myLives({
          start: _this.current,
          limit: _this.display
        })
        .then(res => {
          console.log(res);
          _this.total = res.count;
          _this.list = res.data;
          // this.tabList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.myLive {
  width: 1000px;
  height: 1027px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding: 33px 36px 36px 36px;
  box-sizing: border-box;
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #444444;
    padding-bottom: 28px;
    border-bottom: 1px solid #e7e8ef;
    span {
      width: 4px;
      height: 24px;
      background: #5f2eff;
      border-radius: 2px;
      margin-right: 12px;
      display: inline-block;
    }
  }
  .list {
    .item {
      height: 130px;
      margin-top: 40px;
      .order {
        width: 16px;
        height: 16px;
        position: relative;
        margin-left: 16px;
        img {
          position: relative;
        }
        &::before {
          content: "";
          display: block;
          height: 170px;
          width: 2px;
          background: #f4f0ff;
          position: absolute;
          top: 8px;
          left: 7px;
        }
      }

      .time {
        margin-left: 45px;
        .time-t {
          font-size: 20px;
          color: #333333;
        }
        .time-c {
          font-size: 20px;
          color: #999999;
          margin: 10px 0;
        }
        .time-b {
          width: 80px;
          height: 22px;
          border: 1px solid #5f2eff;
          border-radius: 11px;
          font-size: 12px;
          color: #5f2eff;
        }
      }
      .detail {
        width: 674px;
        height: 130px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(228, 228, 228, 0.7);
        border-radius: 4px;
        position: relative;
        padding: 36px 30px 0 56px;
        box-sizing: border-box;
        margin-left: auto;
        .detail-img {
          width: 68px;
          height: 66px;
          border-radius: 50%;
          background-color: skyblue;
          position: absolute;
          left: -34px;
          top: 33px;
        }
        .detail-name {
          font-size: 20px;
          color: #333333;
        }
        .detail-bottom {
          margin-top: 20px;
          .teacher {
            font-size: 16px;
            color: #888888;
            margin-right: 41px;
          }
          .status {
            font-size: 16px;
            color: #f53444;
            .status-img {
              width: 24px;
              height: 16px;
              margin-left: 14px;
            }
          }
          .detail-btn {
            width: 114px;
            height: 36px;
            border-radius: 18px;
            font-size: 16px;
            color: #ffffff;
            margin-left: auto;
          }
        }
      }
    }
    :last-child {
      .order::before {
        display: none;
      }
    }
  }
}
</style>
